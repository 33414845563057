import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Countdown from '../Countdown';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateWallet } from '../redux/actions/auth';

const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

const useChatStatus = (appContext) => {
  const [activeRequest, setActiveRequest] = useState({
    id: 0,
    astro: 0,
    profile: null,
    type: 1, // 1 for chat and 2 for call
    isActive: false,
  });
  let interval = null;

  const [roomID, setRoomID] = useState(() => '');
  const dispatch = useDispatch();

  const updateActiveRequest = (props = {}) => {
    const {
      id = 0,
      astro = 0,
      profile = null,
      type = 1,
      isActive = false,
    } = props;
    setActiveRequest({
      id,
      astro,
      profile,
      type,
      isActive: isActive && id,
    });
    setRoomID('');
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isChatPage = pathname === '/chat';

  const endChat = () => {
    updateActiveRequest();
    setRoomID('');
  };

  useEffect(() => {
    if (
      !appContext?.user?.accessToken ||
      !activeRequest?.astro ||
      !activeRequest.id ||
      isChatPage ||
      roomID
    )
      return;
    const checkStatus = async () => {
      try {
        if (!activeRequest.id && interval) {
          clearInterval(interval);
          return;
        }
        const cancelRequest = async (method = '') => {
          if (!activeRequest.id) return;
          const res = await axios.patch(
            '/api/cancel-request',
            {},
            {
              params: {
                id: activeRequest.id,
                method: method ? 3 : 6, // 3 for system, 6 for user
              },
            }
          );
          updateActiveRequest();
          if (interval) clearInterval(interval);
          if (res?.data?.isSuccess) {
            if (method === 'timeout') {
              toast.update(toastID, {
                render:
                  'We have cancelled your request as astro is not responding!',
                type: toast.TYPE.ERROR,
                autoClose: 2000,
                isLoading: false,
                closeButton: false,
                icon: false,
              });
            } else {
              toast.update(toastID, {
                render: res?.data?.message || 'Something Went Wrong :(',
                type: toast.TYPE.ERROR,
                autoClose: 2000,
                isLoading: false,
                closeButton: false,
                icon: false,
              });
            }
          } else {
            toast.update(toastID, {
              render: res?.data?.message || 'Something Went Wrong :(',
              type: toast.TYPE.ERROR,
              autoClose: 2000,
              isLoading: false,
              closeButton: false,
              icon: false,
            });
          }
        };

        const CloseButton = () => (
          <button
            onClick={() => cancelRequest()}
            className="close-toast"
            style={{
              backgroundColor: 'transparent',
              color: '#FF9300',
              border: 'none',
              display: 'flex',
              alignItems: 'end',
            }}
          >
            Cancel
          </button>
        );

        const toastID = toast.loading(
          <Countdown
            astroID={activeRequest.astro}
            cancelRequest={cancelRequest}
            id={activeRequest.id}
            method={activeRequest?.type}
          />,
          {
            toastId: 'chatRequest' + activeRequest.id,
            closeButton: CloseButton,
            icon: (
              <img
                src={activeRequest?.profile?.profile_photo}
                alt="active"
                style={{
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '50%',
                }}
                loading="lazy"
              />
            ),
          }
        );

        const checkStatus = throttle(async () => {
          try {
            if (!activeRequest.id) clearInterval(interval);
            setRoomID('');
            const res = await axios.get('/api/check-enrollment-status', {
              params: {
                enrollment_id: activeRequest.id,
              },
            });

            const data = res?.data || {};

            if (!data?.isSuccess) toast.dismiss(toastID);

            const status_code = data?.result?.status_code;

            if (
              status_code === 1 &&
              !toast.isActive('chatRequest' + activeRequest.id)
            ) {
              toast.loading(
                <Countdown
                  astroID={activeRequest.astro}
                  cancelRequest={cancelRequest}
                  id={activeRequest.id}
                  method={activeRequest.type}
                />,
                {
                  toastId: 'chatRequest' + activeRequest.id,
                  closeButton: CloseButton,
                  icon: (
                    <img
                      src={activeRequest?.profile?.profile_photo}
                      alt="active"
                      style={{
                        minWidth: '40px',
                        height: '40px',
                        borderRadius: '50%',
                      }}
                      loading="lazy"
                    />
                  ),
                }
              );
            } else if (
              status_code === 2 ||
              status_code === 5 ||
              status_code === 8
            ) {
              if (data?.result?.request_type === 2) {
                toast.update(toastID, {
                  render: (
                    <div style={{ marginLeft: '20px' }}>
                      {data?.result?.message}
                    </div>
                  ),
                  closeButton: false,
                });
              } else {
                setRoomID(data?.result?.roomID);
                if (interval) clearInterval(interval);
                toast.update(toastID, {
                  render:
                    status_code === 2
                      ? 'Chat request accepted'
                      : 'You have an active chat.',
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                  isLoading: false,
                  closeButton: false,
                  icon: false,
                });
                if (status_code === 5 || status_code === 8) {
                  if (pathname === '/consult-astro') return;
                  updateActiveRequest({
                    id: data?.result?.id,
                    astro: data?.result?.astro_id,
                    profile: data?.result?.astro_profile,
                    type: data?.result?.request_type,
                    isActive: true,
                  });
                  navigate('/consult-astro', {
                    // state: {
                    //   enrollmentId: data?.result?.id,
                    //   astroId: data?.result?.astro_id,
                    //   roomId: data?.result?.roomID,
                    //   userId: data?.result?.user_id
                    // }
                  });
                }
              }
            } else if (
              status_code === 3 ||
              status_code === 7 ||
              status_code === 6
            ) {
              const map = {
                3: 'Request closed.',
                7: 'Request rejected.',
                6: 'Request cancelled.',
              };
              if (map[status_code]) {
                toast.update(toastID, {
                  render: map[status_code],
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                  isLoading: false,
                  closeButton: false,
                  icon: false,
                });
              }
              updateActiveRequest();
              if (interval) clearInterval(interval);
            } else if (status_code === 4 && data?.result?.request_type === 2) {
              const enrollmentId = activeRequest.id;
              updateActiveRequest();

              dispatch(updateWallet(data?.result?.balance));
              toast.update(toastID, {
                render: 'Call completed',
                closeButton: false,
                type: toast.TYPE.SUCCESS,
                autoClose: 2000,
                isLoading: false,
                icon: false,
              });
              if (interval) clearInterval(interval);
              navigate('/consult-astro', {
                state: {
                  enrollmentId,
                },
              });
            }
          } catch (err) {
            toast.dismiss(toastID);
            if (interval) clearInterval(interval);
            return;
          }
        }, 5000);

        interval = setInterval(checkStatus, 5000);
      } catch (err) {
        if (interval) clearInterval(interval);
        return;
      }
    };
    checkStatus();
  }, [appContext?.user?.accessToken, navigate, activeRequest, isChatPage]);

  useEffect(() => {
    const checkOngoing = async () => {
      if (!appContext.isAuthenticated || isChatPage) return;
      try {
        const data = await axios.get('/api/check-ongoing-request', {
          headers: {
            Authorization: `Bearer ${appContext?.user?.accessToken}`,
            // mobile: '+919045220015'
          },
        });

        const { result, isSuccess } = data?.data || {};

        if (isSuccess) {
          const status = result?.status;
          if (result?.id > activeRequest.id) {
            if (status === 1 || status === 2 || status === 5 || status === 8) {
              updateActiveRequest({
                id: result?.id,
                astro: result?.astro_id,
                profile: {
                  ...result?.astro,
                  members: result?.astroMembers?.[0]?.astroMembers,
                },
                type: result?.request_type,
                isActive: status === 5 || status === 8,
              });
              if (result?.request_type === 1) {
                setRoomID(result?.roomID);
                if (interval) clearInterval(interval);
              }
            }
            if ((status === 5 || status === 8) && result?.request_type === 1) {
              toast.info('You have an active chat.', {
                toastId: 'ongoingChat',
              });
              if (pathname === '/consult-astro') return;
              navigate('/consult-astro', {
                // state: {
                //   enrollmentId: result?.id,
                //   astroId: result?.astro_id,
                //   roomId: result?.roomID,
                //   userId: result?.user_id
                // }
              });
            }
          }
        }
      } catch (err) {
        return;
      }
    };

    checkOngoing();
  }, [
    appContext.isAuthenticated,
    appContext.user.accessToken,
    activeRequest.id,
    isChatPage,
    navigate,
  ]);

  const navigateToChat = () => {
    if (pathname === '/chat') return;
    navigate('/chat', {
      state: {
        enrollmentId: activeRequest.id,
        astroId: activeRequest.astro,
        roomId: roomID,
        // userId: activeRequest.user_id
      },
    });
  };

  return {
    updateActiveRequest,
    activeRequest,
    roomID,
    setRoomID,
    isChatPage,
    endChat,
    navigateToChat,
  };
};

export default useChatStatus;
